<template>
    <a-card>
        <a-page-header
            :title='"广告主账号状态"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-form-model-item label="广告主">
                {{ form.advertiserName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="状态">
                <a-radio-group v-model="form.status" @change="onChange">
                    <a-radio :value="1">开启</a-radio>
                    <a-radio :value="2">关闭</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            form: {
                advertiserName: '',
                status: 1
            },
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 18 }
            },
            isSubmit: false,
        }
    },
    created() {
        this.getAccountDetail()
    },
    methods: {
        onChange(e) {
            console.log('radio checked', e.target.value);
        },
        getAccountDetail() {
            this.$api.core.pphtManage.getAccountDetail(this.id).then((res) => {
                if(res.code == 200) {
                    Object.assign(this.form, res.data)
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/pphtManage/accountList'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    let params = {
                        id: this.id,
                        status: this.form.status
                    }
                    this.$api.core.pphtManage.updateAdvertiserStatus( params ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('修改成功')
                            this.$router.push({
                                path: '/pphtManage/accountList'
                            })
                        } else {
                            this.$message.error('修改失败')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>